import { Injectable } from '@angular/core';
import {catchError, map} from 'rxjs/operators';
import { HttpClient, HttpParams,HttpErrorResponse} from '@angular/common/http';
import { Observable,throwError } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebService {

  httpOptions : any;

  public HOST: string = environment.backendUrl;

  constructor(private http: HttpClient) {
    // Http Headers
    this.httpOptions = {
            
    }
  }

  get(url:string,params: HttpParams = new HttpParams()):Observable<any>{
    this.httpOptions.params = params;
  return this.http.get(url, this.httpOptions)
  .pipe(
    catchError(this.handleError)
  );
}

delete(url:string): Observable<any> {
  return this.http.delete(url ,this.httpOptions)
          .pipe(
          catchError(this.handleError)
          );
}

post(object: any, url:string): Observable<any> {
  return this.http.post(url, object, this.httpOptions)
  .pipe(
      catchError(this.handleError)
  );
}

auth(object: any, url:string): Observable<any> {
  return this.http.post(url, object, this.httpOptions)
  .pipe(
      catchError(this.handleError)
  );
}

put(object: any, url:string): Observable<any> {
  return this.http.put(url, object ,this.httpOptions)
  .pipe(
    map(this.extractData),
    catchError(this.handleError)
  );
}

public extractData(res: any) {
  return res.result;
}

public handleError (error: HttpErrorResponse | any) {
  console.log("ERROR")
  console.log(error);
  if (error instanceof Error) {
    // A client-side or network error occurred. Handle it accordingly.
    console.log('An error occurred: ', error.message ? error.message : error.toString());
  } else {
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong,
          if(error.error != undefined){
              console.log(`Backend returned code: ${error.status} - ${error.statusText}, body was: ${error.message}`);
              return throwError(error.error.message);
          }else{
              return throwError(error);
          }
          
  }
  console.log('Raw error:', error);
  return throwError(error);
}

public handleAuthError (error: Response | any) {

  console.log(error);

  switch(error.status){
    case 400: //   No se pudo actualizar, corriga los parámetros inválidos e intente nuevamente
      return throwError('Email o password es incorrecto, intente nuevamente');
      break;
    case 403:
      //   No se pudo actualizar, corriga los parámetros inválidos e intente nuevamente
      return throwError('No posee permisos suficientes para esta accion, contacte con el administrado');
      break;
    default:
    return throwError(error.message);
  }

}

public buildRequestParams(sort?: string, collection?: string, pager?: {pageIndex: number, pageSize: number}){
      
  let params = new HttpParams() ;

  if(sort != undefined){
      params = params.set('sort', sort);
  }
  if(collection != undefined){
      params = params.set('collection', collection);
  }

  if(pager != undefined){
      params = params.set('index', pager.pageIndex.toString());
      params= params.set('size', pager.pageSize.toString());
  }
  
  return params;
}

}
