import { Component, OnInit,Inject } from '@angular/core';
import { WebService } from 'src/app/services/web/web.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-fac-popup',
  templateUrl: './fac-popup.component.html',
  styleUrls: ['./fac-popup.component.css']
})
export class FacPopupComponent implements OnInit {

  responseForm : any;

  constructor(private webService : WebService,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public dialogRef: MatDialogRef<FacPopupComponent>,
  ) { }

  ngOnInit(): void {
    this.responseForm = this.data.form
  }

  exit() {
    this.webService.get(this.webService.HOST + "/process-form-suscriptions/" + this.data.process).subscribe(process =>{
      if(process.result.status != 0){
        this.dialogRef.close(process.result.status_suscription);
      } 
    })

  }

}
