import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { ToastService } from 'src/app/services/notification/toast.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { WebService } from 'src/app/services/web/web.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import {FacPopupComponent} from './fac-popup/fac-popup.component';
import { DomSanitizer} from '@angular/platform-browser';


@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {

  form: any ;
  errorNumber = true;
  numberRequired = false;
  id_process: any;

  constructor(private sanitizer: DomSanitizer,private dialog: MatDialog,private activatedRoute: ActivatedRoute,private webService : WebService, private toast : ToastService, private spinner : SpinnerService) {

    this.spinner.open();
    this.form = new FormGroup({
      name_customer: new FormControl('', [
        Validators.required
      ]),
      lastName_customer:new FormControl('XXXXXXX', [
        Validators.required
      ]),
      identifier_customer: new FormControl('', [
        Validators.required
      ]),
      status_customer : new FormControl(1, [
        Validators.required
      ]) ,
      email_customer: new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      phoneNumber_customer: new FormControl('', [
        Validators.required
      ]),
      plan: new FormControl('1', [
        Validators.required
      ]),
      numberCreditCard: new FormControl('', [ Validators.required
      ]),
      nameHolderCreditCard : new FormControl('', Validators.required),
      expMonthCreditCard: new FormControl('', Validators.required),
      expYearCreditCard: new FormControl('', Validators.required),
      ccv: new FormControl('', Validators.required)
    });

    this.activatedRoute.params.subscribe(param =>{
          this.form.controls["plan"].setValue(Number(param.id));
          this.spinner.close();
    });

   }

  ngOnInit(): void {
  }

  //Permite solo numeros en el numero de la TDC
  onlyNumberKey(event : any){
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  onCountryChange(obj:any){
    //console.log(obj);
  }

  telInputObject(obj:any){
    //console.log(obj);
  }

  getNumber(obj:any){
   this.form.controls["phoneNumber_customer"].setValue(obj);
  }

  hasError(obj:any){
    // FALSE : ERROR - TRUE -> NO ERROR
    this.errorNumber = obj;
    if(obj){
      this.numberRequired = true;
    }
  }

  send(){

    if(!this.form.valid){
      return this.toast.showError("Por favor complete el formulario");
    }

    this.spinner.open();

    const data = { customer:{ 
      name_customer : this.form.value.name_customer,
      lastName_customer : this.form.value.lastName_customer,
      email_customer : this.form.value.email_customer,
      identifier_customer : this.form.value.identifier_customer,
      phoneNumber_customer : this.form.value.phoneNumber_customer,
      status_customer : this.form.value.status_customer,
      code_customer : this.form.value.email_customer
    }, creditcard:{
      numberCreditCard : this.form.value.numberCreditCard.trim(),
      nameHolderCreditCard : this.form.value.nameHolderCreditCard,
      expMonthCreditCard : this.form.value.expMonthCreditCard,
      expYearCreditCard : this.form.value.expYearCreditCard,
      ccv : this.form.value.ccv
    },
    plan : this.form.value.plan,
    param: 1
  }

  this.webService.post(data,this.webService.HOST + "/suscription").subscribe(response =>{

      this.spinner.close();

      if(response.htmlFormData){
        this.id_process = response.id;
        let dialogRef: MatDialogRef<any> = this.dialog.open(FacPopupComponent, {
          width: '50%',
          disableClose: true,
          data: { form: this.sanitizer.bypassSecurityTrustHtml(response.htmlFormData), process: this.id_process }
        })
        dialogRef.afterClosed()
          .subscribe(res => {
            this.myLoadEvent();
          })

      }else{
        this.spinner.close();

        if(response.result.status_suscription == 1){
  
          this.toast.showSuccess("Su suscripción ha sido procesada exitosamente");
  
        }else{
          
          this.toast.showError("Su pago ha sido rechazado por el banco, intentelo de nuevo");

        } 

      }

    }, err =>{
      console.log(err);
      this.spinner.close();

      this.toast.showError(err);

    });

  }

  myLoadEvent(){
    this.webService.get(this.webService.HOST + "/process-form-suscriptions/" + this.id_process).subscribe(process =>{
      if(process.result.status != 0){
        this.spinner.close();
        if(process.result.status_suscription == 1){
  
          this.toast.showSuccess("Su suscripción ha sido procesada exitosamente");
  
        }else{
          
          this.toast.showError("Su pago ha sido rechazado por el banco, intentelo de nuevo");

        }    
      } 
     });   
  }

}
