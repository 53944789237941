import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// Import library module
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormComponent } from './views/form/form.component';
import {Ng2TelInputModule} from 'ng2-tel-input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FacPopupComponent } from './views/form/fac-popup/fac-popup.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatDialogModule} from '@angular/material/dialog';
import { NotFoundComponent } from './views/not-found/not-found.component';


@NgModule({
  declarations: [
    AppComponent,
    FormComponent,
    FacPopupComponent,
    NotFoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    MatDialogModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    Ng2TelInputModule,
    HttpClientModule,
    ToastrModule.forRoot({timeOut: 5000,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true}),
    BrowserAnimationsModule, // ToastrModule added
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
